<template>
    <div class="calendar_box">
        <!-- 头部 -->
        <div class="calendar_title">
            <!-- 编辑按钮 -->
            <el-button
                class="calendar_title_edit"
                @click="getMethods">
                <i class="el-icon-edit"></i>
            </el-button>
            <!-- 左边按钮 -->
            <el-button 
                type="text"
                @click="reduceMonth">
                <i class="el-icon-arrow-left"></i>
            </el-button>
            <!-- 中间显示 -->
            <span>{{nowYear+"年"+nowMonth+"月"}}</span>
            <!-- 右边按钮 -->
            <el-button 
                type="text"
                @click="addMonth">
                <i class="el-icon-arrow-right"></i>
            </el-button>
            <!-- 右侧多选 & 查询按钮 -->
            <div class="calendar_title_rt">
                <!-- 多选框 -->
                <el-select 
                    v-model="selectValue"
                    class="calendar_title_rt_select">
                    <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <!-- 查询按钮 -->
                <el-button type="warning">查询</el-button>
            </div>
        </div>
        <!-- 主体 -->
        <div class="calendar_content">
            <calendar ref="calendar"
                :render-content="renderContent"
                :show-title="false"
                week-title-align="center"
                :week-title="weekTitle"
                :before-render="beforeRender"
                @year-change="changeHandle"
                @month-change="changeHandle">
                <!-- :render-title="renderTitle" -->
            </calendar>
        </div>
    </div>
</template>

<script>
// import Calendar from 'himmas-vue-calendar'

export default {
    data() {
        return {
            // 多选框绑定参数
            selectValue: "1",
            // 多选框选项参数
            options: [
                {
                    value: "1",
                    label: "摄影排期",
                },
                {
                    value: "2",
                    label: "租赁排期",
                },
                {
                    value: "3",
                    label: "场地排期",
                },
            ],
            // 日历控件表格表头
            weekTitle: ['日', '一', '二', '三', '四', '五', '六'],
            // 当前显示年份
            nowYear: "",
            // 当前显示月份
            nowMonth: "",
            // 假定参数
            msgs: [
                {
                    year: "2018",
                    month: "12",
                    day: "18",
                    type:"广告拍摄",
                    field: "珠海赛车场",
                },
                {
                    year: "2019",
                    month: "2",
                    day: "18",
                    type:"广告拍摄",
                    field: "珠海赛车场",
                },
                {
                    year: "2019",
                    month: "1",
                    day: "18",
                    type:"广告拍摄",
                    field: "珠海赛车场",
                },
                {
                    year: "2019",
                    month: "2",
                    day: "22",
                    type:"广告拍摄",
                    field: "珠海赛车场",
                },
            ],
        }
    },
    components: {
        Calendar
    },
    methods: {
        // 月份减少
        reduceMonth(){
            if(this.nowMonth>1 && this.nowMonth<=12){
                this.nowMonth -= 1;
            }else if(this.nowMonth<=1){
                this.nowYear -= 1;
                this.nowMonth = 12;
            }
            this.$refs.calendar.renderThisMonth(this.nowYear,this.nowMonth);
        },
        // 月份增加
        addMonth(){
            if(this.nowMonth>=1 && this.nowMonth<12){
                this.nowMonth += 1;
            }else if(this.nowMonth<=12){
                this.nowYear += 1;
                this.nowMonth = 1;
            }
            this.$refs.calendar.renderThisMonth(this.nowYear,this.nowMonth);
        },
        twoDigit:function(num){ 
            return ('000'+num).slice(-2) 
        },
        // 日历控件自定义函数
        renderContent(h, data){
            var {isToday,isWeekend,isOtherMonthDay, year, day, month, renderYear, renderMonth, lunar, weekDay, festival, term} = data

            // lunar对象中存有农历数据
            var {lunarDayChiness} = lunar;

            // 判断当前日期是否有信息
            let thisType = "", thisField = "";
            for(let val of this.msgs){
                if(val.year==year){
                    if(val.month==month){
                        if(val.day==day){
                            thisType = val.type;
                            thisField = val.field;
                        }
                    }
                }
            }

            //第二第三行展示内容
            let secondInfo = thisType ? thisType : "";
            let thirdInfo = thisField ? thisField : "";


            // -年-月-日
            var dateStr = `${year}-${this.twoDigit(month)}-${this.twoDigit(day)}`

            // 判断是否为节假日
            // var isHoliday = (!!~this.holiday.indexOf(dateStr)) || isWeekend

            return h(
                'div', {
                    class: {
                        'date-box': true,
                        'today':thisType,
                        'weekend':isWeekend,
                        // 'holiday':isHoliday,
                        'other-month':isOtherMonthDay
                    }
                }, [
                    h('div',{
                        class: {
                            'first-info': true
                        }
                    },day),
                    h('div',{
                        class: {
                            'second-info': thisType,
                        }
                    },thisType),
                    h('div',{
                        class: {
                            'second-info': thisField,
                        }
                    },thisField),
                ]
            )
        },
        beforeRender(year,month,next){
            // console.log('before-render',year,month)
            next()
        },
        changeHandle(year,month){
            // console.log('change',year,month)
        },
        // 获取组件方法
        getMethods(){
            console.log(this.$refs.calendar.getRenderedMonth())
        },
    },
    mounted() {
        let now = this.$refs.calendar.getRenderedMonth();
        this.nowYear = now.year;
        this.nowMonth = now.month;
    },
}
</script>

<style>
/* 日历控件头部 */
.calendar_title{
    padding: 20px;
    background: #ddd;
}
/* 头部编辑按钮 */
.calendar_title_edit{
    padding: 5px;
}
/* 头部右侧 */
.calendar_title_rt{
    float: right;
}
/* 右侧多选框 */
.calendar_title_rt_select{
    margin-right: 10px;
}


/* 日历控件主体盒子 */
.calendar_box>.calendar_content{
    position: relative;
    width: 100%;
}
.calendar_box>.calendar_content::before{
    display: block;
    content: "";
    padding-top: 100%;
}
/* 日历控件 */
.calendar_box>.calendar_content>.kl-calendar{
    position: absolute;
    top: 0;
    left: 0;
}
.calendar_box .date-box {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.calendar_box .first-info{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}
.calendar_box .second-info{
    /* flex: 1;
    display: flex;
    justify-content: center; */
    text-align: center;
    color: #999;
    font-size: 12px;
}
.calendar_box .second-info.festival{
    color: #f43;
}
.calendar_box .sign{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background: #f43;
    width: 20px;
    height: 20px;
    color: #fff;
    line-height: 20px;
    text-align: center;
}
.calendar_box .date-box.today{
    background: #fb0;
    color: #fff;
}
.calendar_box .date-box.today .second-info{
    color: #fff;
}
.calendar_box .weekend{
    background: #f6f8fa;
}
.calendar_box .holiday .sign{
    display: block;
}
.calendar_box .date-box.other-month .second-info,.date-box.other-month .first-info{
    color: #999;
}
.calendar_box .date-box:hover{
    border: 3px solid #fb0;
}
.calendar_box .title-box{
    font-size: 20px;
}
</style>
